<template>
  <div class="px-3 py-2">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <img src="@/assets/images/logo/logo.png" alt="logo" width="80" />
        <span class="title-logo"> Smartworks </span>
      </div>

      <b-link
        to="/performance"
        class="d-flex align-items-center bg-white p-1 rounded-pill cursor-pointer"
      >
        &#x2715;
        <span class="bold ml-1">Close</span>
      </b-link>
    </div>

    <div class="text-center mt-3">
      <h2 style="color: #1e1e1e">Review Goals</h2>
      <span class="text-muted">
        Review employee goals and give note them if necessary
      </span>
    </div>

    <b-row class="justify-content-center">
      <b-col
        :md="
          getRole() == 'director' && isMeeting == 'true' && type == 'final'
            ? '12'
            : '8'
        "
      >
        <swiper
          class="swiper-progress mt-3"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(data, index) in swiperData" :key="index">
            <b-card v-if="getRole() == 'director' && isMeeting == 'true'">
              <b-row>
                <b-col :md="type == 'final' ? '6' : '12'">
                  <b-card :title="`Goal ${(index += 1)}`">
                    <b-card-body class="mx-2">
                      <b-form>
                        <b-form-group label="Strategic Target">
                          <b-form-input
                            v-model="data.strategic_goals"
                            readonly
                            size="sm"
                          />
                        </b-form-group>

                        <b-form-group label="Key Performance Indicator">
                          <b-form-textarea
                            v-model="data.key_performance_indicator"
                            readonly
                            size="sm"
                          />
                        </b-form-group>

                        <b-form-group label="Weight">
                          <b-form-input
                            v-model="data.weight"
                            readonly
                            size="sm"
                          />
                        </b-form-group>

                        <b-form-group label="Target">
                          <b-form-input
                            v-model="data.target"
                            readonly
                            size="sm"
                          />
                        </b-form-group>

                        <b-form-group label="Due Date">
                          <b-form-input
                            v-model="data.due_date"
                            readonly
                            size="sm"
                          />
                        </b-form-group>

                        <b-form-group
                          label="Midyear Realization"
                          v-if="data.realization"
                        >
                          <b-form-input
                            v-model="data.realization"
                            size="sm"
                            readonly
                          />
                        </b-form-group>

                        <b-form-group
                          label="Final Realization"
                          v-if="data.final_realization"
                        >
                          <b-form-input
                            v-model="data.final_realization"
                            size="sm"
                            readonly
                          />
                        </b-form-group>

                        <b-form-group
                          label="Final Status"
                          v-if="data.final_goal_status"
                        >
                          <b-form-select
                            v-model="data.final_goal_status"
                            size="sm"
                            :options="[
                              {
                                text: 'Complete',
                                value: 1,
                              },
                              {
                                text: 'Incomplete',
                                value: 0,
                              },
                            ]"
                            disabled
                          />
                        </b-form-group>

                        <b-form-group
                          label="Final Employee Score"
                          v-if="data.final_employee_score"
                        >
                          <b-form-select
                            v-model="data.final_employee_score"
                            :options="[
                              {
                                text: 'Has not met Objectives',
                                value: '0',
                              },
                              {
                                text: 'Met Some Objectives but not All',
                                value: '1',
                              },
                              {
                                text: 'Met Objectives',
                                value: '2',
                              },
                              {
                                text: 'Exceed Some Objectives & Met Others',
                                value: '3',
                              },
                              {
                                text: 'Exceed All Objectives',
                                value: '4',
                              },
                            ]"
                            size="sm"
                            disabled
                          />
                        </b-form-group>

                        <b-form-group
                          label="Final Manager Score"
                          v-if="final_manager_score == true"
                        >
                          <b-form-select
                            v-model="data.final_manager_score"
                            :options="[
                              {
                                text: 'Has not met Objectives',
                                value: '0',
                              },
                              {
                                text: 'Met Some Objectives but not All',
                                value: '1',
                              },
                              {
                                text: 'Met Objectives',
                                value: '2',
                              },
                              {
                                text: 'Exceed Some Objectives & Met Others',
                                value: '3',
                              },
                              {
                                text: 'Exceed All Objectives',
                                value: '4',
                              },
                            ]"
                            size="sm"
                          />
                        </b-form-group>

                        <b-form-group label="Employee Comment" v-if="getRole() != 'director'">
                          <b-form-textarea
                            v-model="data.note"
                            size="sm"
                            placeholder="Write a note"
                          />
                        </b-form-group>
                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-col>
                <b-col v-if="type == 'final'">
                  <b-card title="Manager Evaluation">
                    <b-card-body class="mx-2">
                      <b-form>
                        <b-form-group
                          label="Final Manager Score"
                          v-if="final_manager_score == 'true'"
                        >
                          <b-form-select
                            v-model="data.final_manager_score"
                            :options="[
                              {
                                text: 'Has not met Objectives',
                                value: '0',
                              },
                              {
                                text: 'Met Some Objectives but not All',
                                value: '1',
                              },
                              {
                                text: 'Met Objectives',
                                value: '2',
                              },
                              {
                                text: 'Exceed Some Objectives & Met Others',
                                value: '3',
                              },
                              {
                                text: 'Exceed All Objectives',
                                value: '4',
                              },
                            ]"
                            size="sm"
                            disabled
                          />
                        </b-form-group>

                        <b-form-group label="Final Manager Score">
                          <b-form-select
                            v-model="data.final_manager_score"
                            :options="[
                              {
                                text: 'Has not met Objectives',
                                value: '0',
                              },
                              {
                                text: 'Met Some Objectives but not All',
                                value: '1',
                              },
                              {
                                text: 'Met Objectives',
                                value: '2',
                              },
                              {
                                text: 'Exceed Some Objectives & Met Others',
                                value: '3',
                              },
                              {
                                text: 'Exceed All Objectives',
                                value: '4',
                              },
                            ]"
                            size="sm"
                          />
                        </b-form-group>

                        <b-form-group label="Manager Comment">
                          <b-form-textarea
                            v-model="data.final_manager_comment"
                            size="sm"
                            placeholder="Write a note"
                          />
                        </b-form-group>
                      </b-form>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>
              <b-card-footer
                class="d-flex justify-content-end"
                v-if="getRole() == 'director'"
              >
                <b-button
                  variant="outline-primary"
                  class="rounded-3 mr-1"
                  v-b-modal.send-back-modal
                  size="sm"
                  v-if="isMeeting != 'true'"
                  :disabled="index < swiperData.length"
                >
                  Send Back
                </b-button>
                <b-button
                  variant="primary"
                  class="rounded-3"
                  v-b-modal.approve-modal
                  size="sm"
                  :disabled="index < swiperData.length || data.note != ''"
                  v-if="isMeeting != 'false' && type != 'final'"
                >
                  Approve
                </b-button>
                <b-button
                  variant="primary"
                  class="rounded-3"
                  size="sm"
                  :disabled="index < swiperData.length || data.note != ''"
                  v-else
                  @click="toBehavior"
                >
                  Continue
                </b-button>
              </b-card-footer>
            </b-card>

            <b-card :title="`Goal ${(index += 1)}`" v-else>
              <b-card-body class="mx-2">
                <b-form>
                  <b-form-group label="Strategic Target">
                    <b-form-input
                      v-model="data.strategic_goals"
                      readonly
                      size="sm"
                    />
                  </b-form-group>

                  <b-form-group label="Key Performance Indicator">
                    <b-form-textarea
                      v-model="data.key_performance_indicator"
                      readonly
                      size="sm"
                    />
                  </b-form-group>

                  <b-form-group label="Weight">
                    <b-form-input v-model="data.weight" readonly size="sm" />
                  </b-form-group>

                  <b-form-group label="Target">
                    <b-form-input v-model="data.target" readonly size="sm" />
                  </b-form-group>

                  <b-form-group label="Due Date">
                    <b-form-input v-model="data.due_date" readonly size="sm" />
                  </b-form-group>

                  <b-form-group
                    label="Midyear Realization"
                    v-if="data.realization"
                  >
                    <b-form-input
                      v-model="data.realization"
                      size="sm"
                      readonly
                    />
                  </b-form-group>

                  <b-form-group
                    label="Final Realization"
                    v-if="data.final_realization"
                  >
                    <b-form-input
                      v-model="data.final_realization"
                      size="sm"
                      readonly
                    />
                  </b-form-group>

                  <b-form-group
                    label="Final Status"
                    v-if="data.final_goal_status"
                  >
                    <b-form-select
                      v-model="data.final_goal_status"
                      size="sm"
                      :options="[
                        {
                          text: 'Complete',
                          value: 1,
                        },
                        {
                          text: 'Incomplete',
                          value: 0,
                        },
                      ]"
                      disabled
                    />
                  </b-form-group>

                  <b-form-group
                    label="Final Employee Score"
                    v-if="data.final_employee_score"
                  >
                    <b-form-select
                      v-model="data.final_employee_score"
                      :options="[
                        {
                          text: 'Has not met Objectives',
                          value: '0',
                        },
                        {
                          text: 'Met Some Objectives but not All',
                          value: '1',
                        },
                        {
                          text: 'Met Objectives',
                          value: '2',
                        },
                        {
                          text: 'Exceed Some Objectives & Met Others',
                          value: '3',
                        },
                        {
                          text: 'Exceed All Objectives',
                          value: '4',
                        },
                      ]"
                      size="sm"
                      disabled
                    />
                  </b-form-group>

                  <b-form-group
                    label="Final Manager Score"
                    v-if="final_manager_score == true"
                  >
                    <b-form-select
                      v-model="data.final_manager_score"
                      :options="[
                        {
                          text: 'Has not met Objectives',
                          value: '0',
                        },
                        {
                          text: 'Met Some Objectives but not All',
                          value: '1',
                        },
                        {
                          text: 'Met Objectives',
                          value: '2',
                        },
                        {
                          text: 'Exceed Some Objectives & Met Others',
                          value: '3',
                        },
                        {
                          text: 'Exceed All Objectives',
                          value: '4',
                        },
                      ]"
                      size="sm"
                    />
                  </b-form-group>

                  <b-form-group label="Note (for send back only)">
                    <b-form-textarea
                      v-model="data.note"
                      size="sm"
                      placeholder="Write a note"
                    />
                  </b-form-group>
                </b-form>
              </b-card-body>
              <b-card-footer
                class="d-flex justify-content-end"
                v-if="getRole() != 'staff'"
              >
                <b-button
                  variant="outline-primary"
                  class="rounded-3 mr-1"
                  v-b-modal.send-back-modal
                  size="sm"
                  :disabled="index < swiperData.length"
                >
                  Send Back
                </b-button>
                <b-button
                  variant="primary"
                  class="rounded-3"
                  v-b-modal.approve-modal
                  size="sm"
                  :disabled="index < swiperData.length || data.note != ''"
                  v-if="isMeeting != 'false'"
                >
                  Approve
                </b-button>
                <b-button
                  variant="primary"
                  class="rounded-3"
                  v-b-modal.setup-meeting-modal
                  size="sm"
                  :disabled="index < swiperData.length"
                  v-else
                >
                  Setup Meeting
                </b-button>
              </b-card-footer>
            </b-card>
          </swiper-slide>

          <!-- Add Arrows -->
          <div slot="button-next" class="swiper-button-next" />
          <div slot="button-prev" class="swiper-button-prev" />
          <div slot="pagination" class="swiper-pagination" />
        </swiper>
      </b-col>
    </b-row>

    <b-modal
      id="setup-meeting-modal"
      title="Setup Meeting"
      size="md"
      hide-footer
    >
      <form @submit.prevent="newInboxCreate">
        <b-row>
          <b-col md="6">
            <b-form-group label="Date">
              <flat-pickr
                v-model="date"
                :config="dateConfig"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Time">
              <flat-pickr
                v-model="time"
                :config="timeConfig"
                class="form-control"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Type (Online or offline)">
              <v-select
                v-model="typeMeeting"
                :options="typeOptions"
                :reduce="(option) => option"
                placeholder="Select type"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Address" v-if="typeMeeting == 'offline'">
              <b-form-input
                v-model="addressMeeting"
                size="sm"
                placeholder="Write address"
              />
            </b-form-group>
            <b-form-group label="Url" v-if="typeMeeting == 'online'">
              <b-form-input
                v-model="urlMeeting"
                size="sm"
                placeholder="Write url"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Note">
              <b-form-textarea
                v-model="note"
                size="sm"
                placeholder="Write a note"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </form>
      <div class="d-flex justify-content-end mt-3">
        <b-button
          variant="primary"
          class="rounded-3"
          @click="setupMeeting"
          size="sm"
        >
          Submit
        </b-button>
      </div>
    </b-modal>

    <b-modal id="send-back-modal" title="Send Back" size="sm" hide-footer>
      Are you sure sendback this goal?
      <div class="d-flex justify-content-end mt-3">
        <b-button
          variant="outline-primary"
          class="rounded-3 mr-1"
          @click="sendBack"
          size="sm"
        >
          Yes
        </b-button>
        <b-button
          variant="primary"
          class="rounded-3"
          @click="$bvModal.hide('send-back-modal')"
          size="sm"
        >
          No
        </b-button>
      </div>
    </b-modal>

    <b-modal id="approve-modal" title="Approve" size="sm" hide-footer>
      Are you sure approve this goal?
      <div class="d-flex justify-content-end mt-3">
        <b-button
          variant="outline-primary"
          class="rounded-3 mr-1"
          @click="approve"
          size="sm"
        >
          Yes
        </b-button>
        <b-button
          variant="primary"
          class="rounded-3"
          @click="$bvModal.hide('approve-modal')"
          size="sm"
        >
          No
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<style>
.title-logo {
  font-size: 1.5rem;
  font-weight: 600;
  color: #423ffd;
  margin-left: 0.5rem;
}

.rounded-3 {
  border-radius: 1.75rem;
}

.form-control:read-only {
  background-color: #fff;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      date: "",
      time: "",
      note: "",
      final_manager_score: false,
      typeMeeting: "",
      typeOptions: ["online", "offline"],

      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
      },

      dateConfig: {
        enableTime: false,
        dateFormat: "Y-m-d",
        altInput: true,
        altFormat: "F j, Y",
        allowInput: true,
      },

      swiperData: [],
      /* eslint-disable global-require */

      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },

      goal_id: [],
      endpoint: "",
      type: "",
      isMeeting: false,
      urlMeeting: "",
      addressMeeting: ""
    };
  },

  mounted() {
    if (this.$route.params.id != undefined) {
      localStorage.setItem("ruid", this.$route.params.id);
    }

    if (this.$route.params.type != undefined) {
      localStorage.setItem("rtype", this.$route.params.type);
    }

    if (this.$route.params.isMeeting != undefined) {
      localStorage.setItem("rmeeting", this.$route.params.isMeeting);
    }

    this.isMeeting = this.getFromStorage("rmeeting");

    this.getGoals();
  },

  methods: {
    getGoals() {
      this.type = this.getFromStorage("rtype");
      if (this.type == "midyear") {
        this.endpoint = "/midyear_evaluations/fetch";
      } else if (this.type == "final") {
        this.endpoint = "/final_evaluations/fetch";
      } else {
        this.endpoint = "/goals/fetch";
      }

      this.callApi({
        url: this.endpoint,
        method: "GET",
        params: {
          user_id: this.hashid(this.getFromStorage("ruid")),
          detail: 1,
        },
        success: (res) => {
          if (this.type == "midyear") {
            res.result.map((data) => {
              this.goal_id.push(data.id);
              this.swiperData.push({
                id: data.id,
                strategic_goals: data.goal.strategic_goals,
                key_performance_indicator: data.goal.key_performance_indicator,
                weight: data.goal.weight,
                target: data.goal.target,
                due_date: data.goal.due_date,
                realization: data.midyear_realization,
                note: "",
              });
              this.isMeeting =
                data.status == "new"
                  ? "false"
                  : data.status == "approved_meeting"
                  ? "true"
                  : "false";
            });
          } else if (this.type == "final") {
            res.result.map((data) => {
              this.goal_id.push(data.id);
              this.swiperData.push({
                id: data.id,
                strategic_goals: data.goal.strategic_goals,
                key_performance_indicator: data.goal.key_performance_indicator,
                weight: data.goal.weight,
                target: data.goal.target,
                due_date: data.goal.due_date,
                realization: data.midyear_evaluation.midyear_realization,
                final_realization: data.final_realization,
                final_goal_status: data.final_goal_status,
                final_employee_score: data.final_employee_score,
                note: data.final_employee_comment,
              });
              this.isMeeting =
                data.status == "new"
                  ? "false"
                  : data.status == "approved_meeting"
                  ? "true"
                  : "false";
            });
          } else {
            res.result.map((data) => {
              this.goal_id.push(data.id);
              this.swiperData.push({
                id: data.id,
                strategic_goals: data.strategic_goals,
                key_performance_indicator: data.key_performance_indicator,
                weight: data.weight,
                target: data.target,
                due_date: data.due_date,
                note: "",
              });
            });
          }
        },
      });
    },

    setupMeeting() {
      this.callApi({
        url: "/reminders/create",
        method: "POST",
        data: {
          reminder_type_id: 4,
          created_by: JSON.parse(this.getFromStorage("sw_auth_data")).id,
          assigned_to: this.getFromStorage("ruid"),
          subject: "Reminder for meeting",
          description: this.note,
          url: this.typeMeeting == 'online' ? this.urlMeeting : null,
          adress: this.typeMeeting == 'offline' ? this.addressMeeting : null,
          date: this.date,
          time: this.time,
          start_period: this.date,
          end_period: this.date,
          status: 'new'
        },
      });

      this.type = this.getFromStorage("rtype");

      if (this.type == "goals_setting") {
        this.swiperData.map((item, index) => {
          this.swiperData[index].status = "approved_meeting";
        });
        this.callApi({
          url: `goals/update/${this.hashid(
            this.getFromStorage("ruid")
          )}`,
          method: "POST",
          data: this.swiperData,
          success: (res) => {
            this.$router.push("/performance/goals-setting");
          },
        });
      }

      if (this.type == "midyear") {
        this.swiperData.map((item, index) => {
          this.swiperData[index].status = "approved_meeting";
        });
        this.callApi({
          url: `midyear_evaluations/update/${this.hashid(
            this.getFromStorage("ruid")
          )}`,
          method: "POST",
          data: this.swiperData,
          success: (res) => {
            this.$router.push("/performance/goals-mid-year");
          },
        });
      }

      if (this.type == "final") {
        this.swiperData.map((item, index) => {
          this.swiperData[index].status = "approved_meeting";
        });
        this.callApi({
          url: `final_evaluations/update/${this.hashid(
            this.getFromStorage("ruid")
          )}`,
          method: "POST",
          data: this.swiperData,
          success: (res) => {
            this.$router.push("/performance/goals-final-annual");
          },
        });
      }
    },

    sendBack() {
      this.swiperData.map((data, index) => {
        this.swiperData[index].status = "send_back";
      });

      this.callApi({
        url: `/goals/update/${this.hashid(this.getFromStorage("ruid"))}`,
        method: "POST",
        data: this.swiperData,
        success: (res) => {
          this.$router.push("/performance");
        },
        error: (err) => {
          console.log(err);
        },
      });
    },

    approve() {
      if (this.type == "midyear") {
        this.swiperData.map((item, index) => {
          this.swiperData[index].status = "approved";
        });
        this.callApi({
          url: `midyear_evaluations/update/${this.hashid(
            this.getFromStorage("ruid")
          )}`,
          method: "POST",
          data: this.swiperData,
          success: (res) => {
            this.$router.push("/performance/goals-final-annual");
          },
        });
      }

      this.swiperData.map((data, index) => {
        this.swiperData[index].status = "approved";
      }),
        this.callApi({
          url: `/goals/update/${this.hashid(this.getFromStorage("ruid"))}`,
          method: "POST",
          data: this.swiperData,
          success: (res) => {
            this.$router.push("/performance/goals-setting");
          },
          error: (err) => {
            console.log(err);
          },
        });
    },

    toBehavior() {
      this.callApi({
        url: "goals/fetch",
        method: "GET",
        params: {
          user_id: this.hashid(JSON.parse(this.getFromStorage("ruid"))),
          detail: 1,
        },
        success: (res) => {
          localStorage.setItem("goals", []);
          let goals = [];
          res.result.map((item) => {
            goals.push({
              item,
              other: this.swiperData,
            });
          });

          localStorage.setItem("goals", JSON.stringify(goals));

          this.$router.push("/performance/goal/final_behavior");
        },
      });
    },
  },
};
</script>